import * as ActionTypes from '../action-types'

const initialState = {
   user:{},
   is_logged_in:false,
};

const SignInTemplate = (state= initialState,{type,payload = null}) => {
    // 
    switch(type){
        case 'GET_ANSWER':
            return state 
        default:
            return state;
    }
};

// const getAnswers = (state,payload) => {
//     return {
//         ...state,
//         answers: payload.data
//     };
// };

// const getAnswerQuestions = (state,payload) => {
//     return {
//         ...state,
//         answer_questions: payload.data
//     };
// };

export default SignInTemplate;
