// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("../pages/SignIn")),
  },
];

export default routes;
