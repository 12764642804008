// import modular routes
import signInTemplateRoutes from "./signInTemplate";
import ForgotPasswordTemplateRoutes from "./ForgotPasswordTemplate";
import ChangePasswordTemplateRoutes from "./ChangePasswordTemplate";
import ManageSellerRoutes from "./ManageSeller";
import ManageCatalogRoutes from "./ManageCatalog";
import MarketCatalogRoutes from "./MarketCatalog";
import AddMarketCatalogRoutes from "./MarketCatalog";
import DashboardRoutes from "./DashboardTemplate";
import OrderRoutes from "./Orders";
import WarehouseRoutes from "./Warehouse";
import FinacialReporting from "./FinacialReporting";
import Errors from "./Error";
import ComboProduct from "./ComboProduct";

export default [
  ...FinacialReporting,
  ...AddMarketCatalogRoutes,
  ...ChangePasswordTemplateRoutes,
  ...MarketCatalogRoutes,
  ...ManageCatalogRoutes,
  ...DashboardRoutes,
  ...ForgotPasswordTemplateRoutes,
  ...ManageSellerRoutes,
  ...signInTemplateRoutes,
  ...WarehouseRoutes,
  ...OrderRoutes,
  ...Errors,
  ...ComboProduct,
];
