// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/finacial_reporting",
    component: lazy(() => import("../pages/FinacialReporting")),
  },
];

export default routes;
