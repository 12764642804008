import React, {Suspense} from 'react'
import { Route, Redirect } from 'react-router-dom'

const PublicRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={props => {
        return <Suspense fallback={<div className="loaderScreen"></div>}>
            {
                 <Component {...props}/>
            }
        </Suspense>
    }}/>
}

export default PublicRoute
