import {
  SET_SELLER,
  SET_SELLER_FILES,
  SET_SELLER_INITIAL_STATE,
  SET_CLEAR_STATUTORY_STATE,
} from "../action-types";

const initialState = {
  userId: null,
  seller: { step: 0 },
  is_logged_in: false,
  sellerfile: {},
};

const Seller = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case "SET_SELLER_INITIAL_STATE":
      return setSellerInitialState(state, payload);
    case "SET_SELLER":
      return setSellerValue(state, payload);
    case "SET_SELLER_FILES":
      return setSellerFileValue(state, payload);
    case "SET_CLEAR_STATUTORY_STATE":
      return setClearStatutoryState(state, payload);
    default:
      return state;
  }
};

const setSellerValue = (state, payload) => {
  
  let type = payload.type;
  let record = payload[type];
  let step = payload.step;
  
  return {
    ...state,
    userId: payload.userId,
    seller: { ...state.seller, [type]: record, step },
  };
};

const setSellerFileValue = (state, payload) => {
  
  
  return {
    ...state,
    sellerfile: { ...state.sellerfile, ...payload },
  };
};

const setSellerInitialState = (state, payload) => {
  
  return {
    ...state,
    userId: null,
    seller: { step: 0 },
    is_logged_in: false,
    sellerfile: {},
  };
};

const setClearStatutoryState = (state) => {
  return {
    ...state,

    is_logged_in: false,
    sellerfile: {},
    seller: { ...state.seller, statutory: {}, step: 3 },
  };
};
export default Seller;
