// import lib
import { lazy } from 'react';

const routes = [
  {
    path: '/warehouse',
    component: lazy(() => import('../pages/ManageWarehouse/index.js')),
  },
];

export default routes;
