// import lib
import { lazy } from 'react';

const routes = [
  {
    path: '/manage_order',
    component: lazy(() => import('../pages/Order/')),
  },
  {
    path: '/order_variant/:orderId',
    component: lazy(() => import('../pages/Order/OrderVariants')),
  },
  {
    path: '/order_listing',
    component: lazy(() => import('../pages/Order/OrderListing')),
  },
];

export default routes;