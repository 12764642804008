
import Http from '../Http';
import { SELL_URL } from '../Helper';
import qs from 'qs';
import { set_user_auth, authLogin } from '../store/actions/user';

export function signInUser(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(SELL_URL + 'login', qs.stringify(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then((res) => {
          dispatch(authLogin(res.data.access_token, res.data.refresh_token));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function ping() {
  return () =>
    new Promise((resolve, reject) => {
      //http://13.232.103.131:8083/user/ping
      Http.get(SELL_URL + 'user/ping', {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    })
}

export function renewSession() {
  const body = {
    "refreshToken": sessionStorage.getItem('refresh_token')
  }

  return (dispatch) => {
    new Promise((resolve, reject) => {
      delete Http.defaults.headers.common["Authorization"];
      Http.post(SELL_URL + 'token/refreshToken', body, { "Authorization": "" })
        .then((res) => {
          const tokens = res?.data?.data;
          Http.defaults.headers.common['Authorization'] = `Bearer ${tokens?.accessToken}`;
          dispatch(authLogin(tokens?.accessToken, tokens?.refreshToken));
        })
        .catch((err) => {
          return reject(err);
        });
    })
  }
}

export function getUserDetails(user_name) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(SELL_URL + 'user/v1/' + user_name, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then((res) => {
          dispatch(set_user_auth(res));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function changeSellerPassword(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {

      Http.post(SELL_URL + 'auth/change_password', qs.stringify(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then((res) => {
          return resolve();
        })
        .catch((err) => {
          return reject(err);
        });
    });
}