import { lazy } from 'react';

const routes = [
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    path: '/Error',
    component: lazy(() => import('../pages/common/Error')),
  }
];

export default routes;
