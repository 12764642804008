import axios from 'axios';
import store from './store';
import { renewSession } from './services/AuthService';

const codes = ["TOKEN-1012", "TOKEN-1014", "401"];

axios.interceptors.response.use(
  (response) => {
    const url = response.config.url;
    const statusCode = response?.data?.apiStatus?.statusCode;
    if (codes.includes(statusCode) && !url.includes('register/')) {
      store.dispatch({
        type: "SET_STATUS_CODE",
        payload: statusCode
      })
    }
    else if (statusCode === "TOKEN-1013") {
      store.dispatch(renewSession());
    }
    else {
      const errCode = response?.data?.apiStatus?.statusMessage === "Refresh token created successfully" ?
        store.getState().User.statusCode === "401" ?
          "401" :
          null : null;

      if (!url.includes('register/')) {
        store.dispatch({
          type: "SET_STATUS_CODE",
          payload: errCode
        })
      }
    }
    return response;
  },
  (error) => {

    return Promise.reject(error);
  }
);
export default axios;
