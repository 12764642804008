// import lib
import { lazy } from 'react';

const routes = [
  {
    path: '/seller/index',
    component: lazy(() => import('../pages/ManageSeller')),
  },
  {
    path: '/seller/organization',
    component: lazy(() => import('../pages/ManageSeller/organizationDetails')),
  },
  {
    path: '/seller/bank_details',
    component: lazy(() => import('../pages/ManageSeller/bankDetails')),
  },
  {
    path: '/seller/statutory_details',
    component: lazy(() => import('../pages/ManageSeller/statutoryDetails')),
  },
  {
    path: '/seller/market_details',
    component: lazy(() => import('../pages/ManageSeller/marketDetails')),
  },
  {
    path: '/seller/gst_details',
    component: lazy(() => import('../pages/ManageSeller/gstDetails')),
  },
  {
    path: '/seller/warehouse_details',
    component: lazy(() => import('../pages/ManageSeller/warehouseDetails')),
  },
  {
    path: '/seller/shipping_details',
    component: lazy(() => import('../pages/ManageSeller/shippingDetails')),
  },
  {
    path: '/seller/step',
    component: lazy(() => import('../pages/ManageSeller/step')),
  },
  {
    path: '/seller/roles_responsbilities',
    component: lazy(() => import('../pages/ManageSeller/roles')),
  },
];

export default routes;
