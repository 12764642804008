import { AUTH_USER } from '../action-types';
import HTTP from '../../Http';

const initialState = {
  productDetails: {},
  marketDetails: {},
};

const Catalog = (state = initialState, { type, payload = null }) => {
  
  
  
  switch (type) {
    case 'SET_CATALOG':
      return setCatalog(state, payload);
    case 'RESET_CATALOG':
      return resetCatalog();
    case 'SET_MARKET':
      return setMarket(state, payload);
    case 'RESET_MARKET':
      return resetMarket();
    default:
      return state;
  }
};

const setCatalog = (state, payload) => {
  return {
    ...state,
    productDetails: payload,
  };
};

const resetCatalog = () => {
  return {
    productDetails: {},
  };
};

const resetMarket = () => {
  return {
    marketDetails: {},
  };
};

const setMarket = (state, payload) => {
  return {
    ...state,
    marketDetails: payload,
  };
};

export default Catalog;
