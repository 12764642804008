import { useCallback, useState } from "react";

//Production

export let BASE_URL = "https://masterservice.agrozone.in/master/";
export let ADMIN_URL = "https://masterservice.agrozone.in/master/";
export let SELLER_URL = "https://sellerservice.agrozone.in/seller/";
export let SELL_URL = "https://sellerservice.agrozone.in/";
export let PRODUCT_URL = "https://productservice.agrozone.in/";
export let ORDER_URL = "https://orderservice.agrozone.in/";
export let SHIPPER_URL = "https://shipperservice.agrozone.in/";

//UAT

// export let BASE_URL = "https://uat-masterservice.agrozone.in/master/";
// export let ADMIN_URL = "https://uat-masterservice.agrozone.in/master/";
// export let SELLER_URL = "https://uat-sellerservice.agrozone.in/seller/";
// export let SELL_URL = "https://uat-sellerservice.agrozone.in/";
// export let PRODUCT_URL = "https://uat-productservice.agrozone.in/";
// export let ORDER_URL = "https://uat-orderservice.agrozone.in/";
// export let SHIPPER_URL = "https://uat-shipperservice.agrozone.in/";

//DEV

// export let BASE_URL = "https://dev-masterservice.agrozone.in/master/";
// export let ADMIN_URL = "https://dev-masterservice.agrozone.in/master/";
// export let SELLER_URL = "https://dev-sellerservice.agrozone.in/seller/";
// export let SELL_URL = "https://dev-sellerservice.agrozone.in/";
// export let PRODUCT_URL = "https://dev-productservice.agrozone.in/";
// export let ORDER_URL = "https://dev-orderservice.agrozone.in/";
// export let SHIPPER_URL = "https://dev-shipperservice.agrozone.in/";

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 5 });
    }
  }, []);
  return [translate, containerRef];
};
