export const SET_SELLER = "SET_SELLER";
export const SET_STATES = "SET_STATES";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_USER = "AUTH_USER";
export const SET_CATALOG = "SET_CATALOG";
export const RESET_CATALOG = "RESET_CATALOG";
export const SET_MARKET = "SET_MARKET";
export const RESET_MARKET = "RESET_MARKET";
export const SET_SELLER_FILES = "SET_SELLER_FILES";
export const SET_SELLER_INITIAL_STATE = "SET_SELLER_INITIAL_STATE";
export const SET_CLEAR_STATUTORY_STATE = "SET_CLEAR_STATUTORY_STATE";
export const SET_INTERVAL_ID = "SET_INTERVAL_ID";
