import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/online_promotion_products",
    component: lazy(() =>
      import("../pages/PromotionProducts/onlinePromotionProducts")
    ),
  },
];

export default routes;
