import { SET_STATES } from '../action-types';

const initialState = {
  state: {},
};

const State = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case 'SET_STATES':
      return setStateValue(state, payload);
    default:
      return state;
  }
};

const setStateValue = (state, payload) => {
  
  return {
    state: payload,
  };
};

export default State;
