import { AUTH_USER, AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT, SET_INTERVAL_ID } from '../action-types';

export const set_user_auth = (payload) => {
  return {
    type: AUTH_USER,
    payload: payload,
  };
};

export function authCheck() {
  return {
    type: AUTH_CHECK,
  }
}

export function authLogin(accessToken, refreshToken) {
  return {
    type: AUTH_LOGIN,
    accessToken,
    refreshToken
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  }
}

export function setIntervalID(value) {
  return {
    type: SET_INTERVAL_ID,
    payload: value
  }
}
